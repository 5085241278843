import { useDocumentIssuanceVM } from "presentation/hook/DocumentIssuance/useDocumentIssuanceVM";
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import DocumentIssuanceTablePanel from "./Table/DocumentIssuanceTablePanel";

const DocumentIssuanceMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [documentIssuanceState] = useDocumentIssuanceTracked();
    const documentIssuanceVM = useDocumentIssuanceVM();
    const { isBackMaster } = documentIssuanceState;

    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    documentIssuanceVM.searchDocIssuance()
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });

    }, [isBackMaster, documentIssuanceVM])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper${false ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"100%"}
                rightSectionWidth={"0%"}
                leftChildren={<DocumentIssuanceTablePanel />}
            /*rightChildren={<TariffCodeSearchPanel/>} */
            />
        </div>
    </>
}

export default memo(DocumentIssuanceMaintenance);