import _ from "lodash"
import { DocumentIssuanceConstant } from "presentation/constant/DocumentIssuance/DocumentIssuanceConstant"
import { useDocumentIssuanceVM } from "presentation/hook/DocumentIssuance/useDocumentIssuanceVM"
import { useMessageBarVM } from "presentation/hook/useMessageBar"
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider"
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer"
import { NbisCommonField } from "presentation/view/components/NbisCommonField"
import { useCallback, useMemo, useState } from "react"
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core"
import { SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled"

export const DocumentSupportingPanel = () => {
    const [docIssuanceState] = useDocumentIssuanceTracked();
    const docIssuanceVM = useDocumentIssuanceVM();
    const messageBarVM = useMessageBarVM();

    const { detailState, dynamicOptions } = docIssuanceState;
    const { docSupportingList, isAdd, isEditable, isRead, isSaveClicked, allFormState } = detailState;
    const currentRow = detailState.currentRow;
    const DOC_SUPPORTING_CONSTANT = DocumentIssuanceConstant.Detail;
    const [isLoading, setIsLoading] = useState(false);

    const memoSupportingDocOptions = useMemo(() => {
        const chgTypeDropOpts = dynamicOptions.chargeTypeDropdownOptions;

        let supportDocKey;
        if (currentRow.chargeType) {
            const tempchgTypeDropOpt = chgTypeDropOpts.find(tempChgType => tempChgType.value === currentRow.chargeType);
            let chargeCategory = tempchgTypeDropOpt?.tagLabel;

            supportDocKey = currentRow.chargeType;
            if (chargeCategory === "USER"
                || (chargeCategory === "GEN" && supportDocKey !== "EC" && supportDocKey !== "RSC"
                    && supportDocKey !== "SC" && supportDocKey !== "YOC" && supportDocKey !== "RSCD1"
                    && supportDocKey !== "RSCL2")
            ) {
                supportDocKey = chargeCategory;
            }
        }

        const groupOfSupportingDocOptions = dynamicOptions.supportingDocDropdownOptions[supportDocKey ?? ""];
        const commonSupportingDocOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfSupportingDocOptions ?? [])), 'value'), 'dropdownLabel');
        return commonSupportingDocOptions;
    }, [currentRow.chargeType, dynamicOptions.chargeTypeDropdownOptions, dynamicOptions.supportingDocDropdownOptions]);

    const memoDetailPrintOptions = useMemo(() => {
        const groupOfDtlPrintOptions = dynamicOptions.detailPrintingDropdownOptions[currentRow.chargeType ? "NON-EMPTY" : "EMPTY"];
        const commonDtlPrintOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfDtlPrintOptions ?? [])), 'value'), 'dropdownLabel');
        return commonDtlPrintOptions;
    }, [currentRow.chargeType, dynamicOptions.detailPrintingDropdownOptions]);

    const handleCancelClick = () => {
        docIssuanceVM.onDocSupportingCancel();
    }

    const handleSaveClick = useCallback(async () => {
        if (isAdd && !_.isEmpty(docSupportingList) && docSupportingList.find(item => item.chargeType === currentRow.chargeType)) {
            messageBarVM.showError('Duplicate record found, please check the key: Charge Type.');
            return;
        }

        setIsLoading(true);
        docIssuanceVM.onDocSupportingSaveClicked();
        const res = await docIssuanceVM.onTempSaveDocSupporting(currentRow);
        setIsLoading(false);
        if (res) {
            messageBarVM.showError(res["mandatoryCheckFail"]);
        }
    }, [currentRow, docIssuanceVM, docSupportingList, isAdd, messageBarVM])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={true}
                readOnlyValue={currentRow?.chargeType ?? ''}
                fieldValue={currentRow?.chargeType ?? ''}
                fieldLabel={DOC_SUPPORTING_CONSTANT.CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                options={dynamicOptions.chargeTypeDropdownOptions}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    docIssuanceVM.onSupportingFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DOC_SUPPORTING_CONSTANT.CHARGE_TYPE, allFormState, currentRow?.chargeType, docIssuanceVM, dynamicOptions.chargeTypeDropdownOptions, isAdd, isSaveClicked])

    const memoDetailPrinting = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentRow?.detailPrinting ?? ''}
                fieldValue={currentRow?.detailPrinting ?? ''}
                fieldLabel={DOC_SUPPORTING_CONSTANT.DETAIL_PRINTING}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'detailPrinting'}
                options={memoDetailPrintOptions}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    docIssuanceVM.onSupportingFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DOC_SUPPORTING_CONSTANT.DETAIL_PRINTING, allFormState, currentRow?.detailPrinting, docIssuanceVM, isRead, isSaveClicked, memoDetailPrintOptions])

    const memoSupportingDoc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "390px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                isMultipleDropDown={true}
                readOnlyValue={currentRow?.supportingDoc ?? ''}
                fieldValue={currentRow?.supportingDoc?.toString().split(",")?.filter(item => item.trim() !== '') ?? ''}
                fieldLabel={DOC_SUPPORTING_CONSTANT.SUPPORTING_DOCUMENT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'supportingDoc'}
                options={memoSupportingDocOptions}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    docIssuanceVM.onSupportingFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [DOC_SUPPORTING_CONSTANT.SUPPORTING_DOCUMENT, allFormState, currentRow?.supportingDoc, docIssuanceVM, isRead, isSaveClicked, memoSupportingDocOptions])

    return (
        <div className='side-form-content-wrapper' style={{ position: 'relative', height: "100vh-128px" }}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            <SidebarTitle>{isAdd ? 'NEW SUPPORTING DOCUMENT' : currentRow.chargeType}</SidebarTitle>
                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '50vh', maxHeight: '50vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoChargeType}
                            {memoDetailPrinting}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSupportingDoc}
                        </CriteriaItemContainer>
                    </div>
                </div>
                <div className='im-charge-data-search-criteria-buttons' >
                    {
                        <>
                            <HPHButton label={isRead ? 'Close' : 'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                            {!isRead && <HPHButton label={isAdd ? 'Add' : "Update"} size={'Small'} theme={'Primary'} onClick={handleSaveClick} />}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

