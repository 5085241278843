import { CustomCellRendererProps } from "ag-grid-react";
import { INTEGER } from "presentation/utils/numberUtil";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { DocumentIssuanceConstant } from "./DocumentIssuanceConstant";

const SCREEN_CONSTANT = DocumentIssuanceConstant;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_DOCUMENT_ISSUANCE_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.priority,
        field: "priority",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        pinned: "left",
        dataType: "number",
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SCREEN_CONSTANT.BILL_TO_COMPANY,
        field: "billToCompany.companyCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.CUSTOMER_CODE,
        field: "customer.customerCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.CHARGE_TYPE,
        field: "chargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.ISSUE_TYPE,
        field: "issueType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.DOC_TYPE,
        field: "docType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.NO_OF_COPIES,
        field: "noOfCopy",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        dataType: INTEGER,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SCREEN_CONSTANT.NO_OF_SUPPORTING_DOC,
        field: "noOfSupportingDoc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 220,
        dataType: INTEGER,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SCREEN_CONSTANT.NO_OF_ORIGINAL,
        field: "noOfOriginal",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
        dataType: INTEGER,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SCREEN_CONSTANT.ASTERISK_LABEL,
        field: "asteriskLabel",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.LANGUAGE,
        field: "docLanguage",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
    },
    {
        headerName: SCREEN_CONSTANT.AMOUNT_PRINTING,
        field: "amountPrinting",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.DISPLAY_BILLING_MSG,
        field: "displayBillingMsg",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.DISPLAY_2ND_CURRENCY,
        field: "display2ndCurrency",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 220,
    },
    {
        headerName: SCREEN_CONSTANT.EMAIL_ADDRESS,
        field: "emailAddress",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.CC,
        field: "copyTo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.EDI_EMAIL_ADDRESS,
        field: "ediEmailAddress",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.REPORT_TEMPLATE,
        field: "report.defaultReportName",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.INVOICE_CURRENCY,
        field: "invoiceCurrency",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}
